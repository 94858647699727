import React, { useState } from 'react';
import "../../assets/css/Modal.scss";

const SimpleModal = ({ modalBody, setModalBody,ModalViewers,ModalButton}) => {
  return (
    <>
      <div
        className={`modal fade unqlifiedmodal-pop ${modalBody === '' ? 'hide' : 'show'}`}
        tabIndex={-1}
        role='dialog'
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }}>
        <div
          className='modal-dialog modal-dialog-centered modal-md'
          role='document'>
          <div className='modal-content bor-b5'>
            {/* <div className='modal-header noborder' /> */}
            <div className='modal-body'>
              <p className='unqualify-modal-p text-center fw-bold mt-4'>{modalBody}</p>
            </div>
            <div className='modal-footer justify-content-center'>
              <button
                type='button'
                className='btn btn-secondary btn-anime btn_reClaim'
                data-dismiss='modal'
                onClick={(e) => { ModalViewers(1) }}>
                {ModalButton == "" || ModalButton == undefined ? ("Claim Your Benefits") : (ModalButton)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimpleModal;
