import React, { useState } from "react";
import TextField from "../../../UI/TextField";
import Loader from "./Loader";
import shield from "../../../../assets/img/SPH_CDV1/shield.png"
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";

const UserDetails = (props) => {
   
    const { phoneValidation } = usePhoneValidation();
    const namevalidate = (e) => {
        const values = props.getValues();
        if (e.target.value == "" || e.target.value.trim().length < 3) {
            props.setError('txtFName', {
                type: "manual",
                message: "Your name is invalid."
            })
        }
        else{
            props.clearErrors();
        }
    }

    const mobileValidate = async () => {
        const values = props.getValues();
        const phone = values['txtPhone'];
        if (phone == "") {
            props.setError("txtPhone", {
                type: "manual",
                message: "Please enter the phone number.",
            });
        }
          if (phone !== "") {
            const getPhoneValidation = await phoneValidation(phone);
            if (getPhoneValidation.data.status_code === 0) {
                props.setError("txtPhone", {
                    type: "manual",
                    message: "Please enter the phone number.",
                });
                return 0;
            } else if (getPhoneValidation.data.status_code === 2) {
                props.setError("txtPhone", {
                    type: "manual",
                    message: "Phone Number Already Exist",
                });
                return 0;
            } else if (getPhoneValidation.data.status_code === 0) {
                props.setError("txtPhone", {
                    type: "manual",
                    message: "Please Enter Valid Phone Number",
                });
                return 0;
            }
            else {
                return 1;
            }
        }
    }

    const zipcodeValidate = () => {
        const values = props.getValues();
        const txtZip = values['txtZip'];
        if (txtZip == '' || txtZip.length < 3 ) {
            props.setError("txtZip", {
                type: "manual",
                message: "Please enter a valid state name",
            });
            return 0;
        }
        else{
            return 1;
        }

    }
    return (
        <>
           
            <Loader loader={props.loader} />
            <section className={`banner_section ${props.showQuestionSlide}`} id="myDiv2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 order-lg-last order-md-last">
                            <div className="badge01">
                                <div className="content">
                                    
                                    <table className="table_1">
                                        <tbody>
                                            <tr>
                                            <td>
                                                    <img src={props.contrypic} alt="" />
                                                </td>
                                            </tr>
                                            <tr>
                                                
                                                <td>{props.countryRate}/month*</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h5>Average Premium Tax Credit in {props.getState == "Kerala" || props.getState == "" ? "California" : props.getState}</h5>
                                </div>
                            </div>
                        </div>
                        <div className={`col-lg-8 col-md-8 col-sm-12 col-12 main_sec order-lg-first order-md-first ${props.showQuestionSlide}`}>
                            <h2>It's good news!</h2>
                            <p className="sub_head01">
                                <span>
                                Our records show you may qualify for maximum subsidy. We just need a few more details for enrollment.
                                </span>
                            </p>
                            <div className="form_slide">
                                {/* slide_1 */}
                                <div className="slides_frm field_frm" id="slide05">
                                    <div className="row field_1">
                                        <div className="col-md-6 mt-3">
                                        <label>First Name</label>
                                            <TextField
                                                type="text"
                                                name="txtFName"
                                                id="txtFName"
                                                className="inputfield_n1"
                                                placeholder="First Name"
                                                onBlur={(e) => namevalidate(e)}
                                                onKeyPress={e => (!/^[a-zA-Z\s]+$/.test(e.key)) && e.preventDefault()}
                                                validation={props.register({
                                                    required: "Your name is invalid.",
                                                    minLength: {
                                                        value: 3,
                                                        message: "Your name is invalid.",
                                                    },
                                                    pattern: {
                                                        value: /^[a-zA-Z\s]+$/,
                                                        message: "Your name is invalid.",
                                                    },
                                                })}
                                            />
                                            {props.validationMsg.txtFName && <span class="error_inpt">{props.validationMsg.txtFName.message} </span>}
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label>Mobile Phone Number</label>
                                            <div className="input-group">
                                                <TextField
                                                    type="tel"
                                                    name="txtPhone"
                                                    id="txtPhone"
                                                    className="inputfield_phn form-control"
                                                    placeholder="Mobile Phone Number"
                                                    onBlur={mobileValidate}
                                                    onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
                                                    maxlength="11"
                                                    validation={props.register({
                                                        pattern: {
                                                            value: /^[0-9]*$/i,
                                                            message: "Please enter the phone number.",
                                                        },
                                                        required: "Please enter the phone number.",
                                                    })}
                                                />
                                                <span className="secure-badge">
                                                    <span>
                                                        <img
                                                            src={shield}
                                                            style={{ height: 24 }}
                                                            alt=""
                                                        />
                                                        Secure
                                                    </span>
                                                </span>
                                            </div>
                                            {props.validationMsg.txtPhone && <span class="error_inpt">{props.validationMsg.txtPhone.message} </span>}
                                        </div>
                                        <div className="col-md-6 mb-3 mt-3">
                                        <label>State</label>
                                            <TextField
                                                type="text"
                                                name="txtZip"
                                                id="txtZip"
                                                value={props.getState}
                                                className="inputfield_n1"
                                                placeholder="State"
                                                onBlur={zipcodeValidate}
                                                onKeyPress={(e) => {
                                                    if (!isNaN(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                validation={props.register({
                                                    required: "Please enter a valid state name",
                                                    minLength: {
                                                        value: 5,
                                                        message: "Please enter a valid state name",
                                                    },
                                                })}
                                            />
                                            {props.validationMsg.txtZip && <span class="error_inpt">{props.validationMsg.txtZip.message} </span>}
                                        </div>
                                    </div>
                                    
                                    <div style={{ width: "100%" }}>
                                        <button type="button" className="btn_next01" id="next_thank" onClick={props.handleSubmit(props.handleClick)}>
                                        Next <i className="fa-solid fa-angle-right" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* </div> */}

        </>
    )
}
export default UserDetails;