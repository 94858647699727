// import { lazy } from 'react';
// const Split_1 =  import('./Components/Pages/Splits/Split_1');

// export {
//     Split_1
// }

import React, { lazy } from 'react';

const ELTP_V2 = lazy(() => import('./Components/Pages/ELTP_V2/ELTP_V2'));
const ELTP_V1 = lazy(() => import('./Components/Pages/ELTP_V1/ELTP_V1'));
// const ACA_BQ_V1 = lazy(() => import('./Components/Pages/ACA_BQ_1/ACA_BQ_V1'));
// const ACA_BQ_V2_1 = lazy(() => import('./Components/Pages/ACA_BQ_2/ACA_BQ_V2_1'));
// const ACA_BQ_V2_2 = lazy(() => import('./Components/Pages/ACA_BQ_2/ACA_BQ_V2_2'));
// const ACA_BQ_V2_3 = lazy(() => import('./Components/Pages/ACA_BQ_2/ACA_BQ_V2_3'));
// const ACA_BQ_MA_V2_1 = lazy(() => import('./Components/Pages/ACA_BQ_2/ACA_BQ_MA_V2_1'));
// const ACA_SPH_V3 = lazy(() => import('./Components/Pages/ACA_SPH_V3/ACA_SPH_V3'));
// const ACA_BQ_V2_4 = lazy(() => import('./Components/Pages/ACA_BQ_2/ACA_BQ_V2_4'));
// const ACA_BQ_V2_5 = lazy(() => import('./Components/Pages/ACA_benefit_quiz/ACA_benefit_quiz'));
// const ACA_BQ_V3 = lazy(() => import('./Components/Pages/ACA_BQ_V3/ACA_BQ_V3'));
// const ACA_BQ_V3_5 = lazy(() => import('./Components/Pages/ACA_BQ_V3_5/ACA_BQ_V3_5'));
// const ACA_SPH_V3_5 = lazy(() => import('./Components/Pages/ACA_SPH_V3_5/ACA_SPH_V3_5'));
// const ACA_BQ_V3_5B = lazy(() => import('./Components/Pages/ACA_BQ_V3_5B/ACA_BQ_V3_5B'));
// const ACA_SPH_V4 = lazy(() => import('./Components/Pages/ACA_SPH_V4/ACA_SPH_V4'));
// const ACA_SPH_V5 = lazy(() => import('./Components/Pages/ACA_SPH_V5/ACA_SPH_V5'));
// const ACA_SPH_V5B = lazy(() => import('./Components/Pages/ACA_SPH_V5B/ACA_SPH_V5B'));
// const ACA_SPH_V6 = lazy(() => import('./Components/Pages/ACA_SPH_V6/ACA_SPH_V6'));
// const ACA_SPH_V6C = lazy(() => import('./Components/Pages/ACA_SPH_V6C/ACA_SPH_V6C'));
// const ACA_SPH_V6B = lazy(() => import('./Components/Pages/ACA_SPH_V6B/ACA_SPH_V6B'));
// const ACA_SPH_V6B2 = lazy(() => import('./Components/Pages/ACA_SPH_V6B2/ACA_SPH_V6B2'));
// const ACA_SPH_V6B_MS = lazy(() => import('./Components/Pages/ACA_SPH_V6B_MS/ACA_SPH_V6B_MS'));
// const ACA_SPH_V7 = lazy(() => import('./Components/Pages/ACA_SPH_V7/ACA_SPH_V7'));
// const ACA_SPH_V7_1 = lazy(() => import('./Components/Pages/ACA_SPH_V7_1/ACA_SPH_V7_1'));
// const ACA_SPH_V7B = lazy(() => import('./Components/Pages/ACA_SPH_V7B/ACA_SPH_V7B'));
// const ACA_SPH_V8_4 = lazy(() => import('./Components/Pages/ACA_SPH_V8_4/ACA_SPH_V8_4'));

const AppSplitList = (props) => {

    const SplitListMap = {
        'ELTP_V2': ELTP_V2,
        'ELTP_V1': ELTP_V1,
        // 'ACA_BQ_V2_1': ACA_BQ_V2_1,
        // 'ACA_BQ_V2_2': ACA_BQ_V2_2,
        // 'ACA_BQ_V2_3': ACA_BQ_V2_3,
        // 'ACA_BQ_MA_V2_1': ACA_BQ_MA_V2_1,
        // 'ACA_SPH_V3': ACA_SPH_V3,
        // 'ACA_SPH_V4': ACA_SPH_V4,
        // 'ACA_BQ_V2_4': ACA_BQ_V2_4,
        // 'ACA_BQ_V2_5': ACA_BQ_V2_5,
        // 'ACA_SPH_V5': ACA_SPH_V5,
        // 'ACA_BQ_V3': ACA_BQ_V3,
        // 'ACA_SPH_V6': ACA_SPH_V6,
        // 'ACA_SPH_V6C': ACA_SPH_V6C,
        // 'ACA_SPH_V6B': ACA_SPH_V6B,
        // 'ACA_BQ_V3_5': ACA_BQ_V3_5,
        // 'ACA_SPH_V3_5': ACA_SPH_V3_5,
        // 'ACA_SPH_V5B': ACA_SPH_V5B,
        // 'ACA_BQ_V3_5B': ACA_BQ_V3_5B,
        // 'ACA_SPH_V7B': ACA_SPH_V7B,
        // 'ACA_SPH_V7': ACA_SPH_V7,
        // 'ACA_SPH_V7_1': ACA_SPH_V7_1,
        // 'ACA_SPH_V6b_ms': ACA_SPH_V6B_MS,
        // 'ACA_SPH_V8_4': ACA_SPH_V8_4,
        // 'ACA_SPH_V6B2': ACA_SPH_V6B2
    };
    const SplitComponent = SplitListMap[props.splitName];
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    )

}

export default AppSplitList;