import React from 'react'
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            {/* =========FOOTER-SECTION ===========*/}
            <footer className="py-4 text-center">
                <div className="container">
                    <div className="offset-lg-1 col-lg-10">
                        <div className="row mb-3">
                            <div className="py-2">
                                <p>
                                We are not affiliated with or endorsed by any government entity. CMS has not reviewed or approved this information. This is not a complete description of benefits, visit {" "}
                                    <a
                                        className="foot"
                                        target="_blank"
                                        href="https://www.healthcare.gov/"
                                    >
                                        healthcare.gov
                                    </a>{" "} for a full list of available plans and benefits. Benefits may be limited to specific plans and regions. The listed benefits are offered as part of some ACA Health Plans and Medicare Advantage Plans; enrollment will replace your current health coverage. There is no obligation to enroll.
                                </p>

                            </div>
                            <div className=" py-2">
                                <p>
                                    *Not all plans or benefits are available in all areas. This benefit is only available to eligible applicants in the following states: AL, AZ, FL, GA, MS, NC, SC, TX, UT, VA. Not all callers from these states will qualify for the stated benefit.
                                </p>
                            </div>
                              <div>
                                <p>Javier Eduardo Polo</p>
                              </div>
                            <div className="link_secc">
                                *<a className="foot"
                                    target="_blank"
                                    href="https://www.cbo.gov/publication/59273"
                                >
                                    CBO.GOV
                                </a>
                            </div>
                            <div className="link_secc">
                                *<a className="foot"
                                    target="_blank"
                                    href="https://www.kff.org/health-reform/state-indicator/average-monthly-advance-premium-tax-credit-aptc/?currentTimeframe=0&sortModel=%7B%22colId%22:%22Location%22,%22sort%22:%22asc%22%7D"
                                >
                                    KFF.ORG
                                </a>
                            </div>
                            <div className="link_sec">
                                <p>
                                    © 2013-2022 |{" "}
                                    <Link target="_blank" to="Privacy-policy">Privacy Policy</Link>
                                    |{" "}
                                    <Link target="_blank" to="Terms-of-use">Terms of Use</Link>
                                    |
                                    {" "}
                                    <Link target="_blank" to="Cookies-policy" >Cookie Policy</Link>
                                    {" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer