import React from 'react'
import logo from "../../../../assets/img/SPH_CDV1/logo.png";
import ObamacareLogo from "../../../../assets/img/SPH_CDV1/Obamacare-Logo.png";
import { Helmet } from 'react-helmet';
import * as EnvConstants from "../../../../../src/Constants/EnvConstants";

const EnvConstant = EnvConstants.AppEnv;

const Header = () => {
    return (
        <>
            {/*=========== HEADER-SECTION ===========*/}
            {(EnvConstant == "prod") ? <Helmet><script src="//b-js.ringba.com/CAfe8e30f0d9964386bac7acabadf4a10b" async></script></Helmet> : <Helmet><script src="//b-js.ringba.com/CA132c69ac2eb5450c9fbcddbfe2a49ea4" async></script></Helmet>}
            {/* <Helmet><script src="//b-js.ringba.com/CAfe8e30f0d9964386bac7acabadf4a10b" async></script></Helmet> */}
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                            <a href="">
                                <img src={logo} className="logo_main1" alt="" />
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end hdr_imgs ">
                            <img
                                src={ObamacareLogo}
                                className="union-flag"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header