
const UnqualifiedRedirectionLinkEnglish = (option_id) => {
    const urlParams = new URLSearchParams(window.location.search);
    const a = (urlParams.get('atp_sub7')) ? urlParams.get('atp_sub7') : '33';
    // const c = '1992';
    const p = 'c';
    const s1 = 'lp';
    const s2 = (urlParams.get('atp_source')) ? urlParams.get('atp_source') : '';
    const s3 = (urlParams.get('facebook_cid')) ? urlParams.get('facebook_cid') : '';
    const s4 = (urlParams.get('crvid')) ? urlParams.get('crvid') : '';
    const s5 = (urlParams.get('uuid')) ? urlParams.get('uuid') : ''; 
    const s6 = (urlParams.get('atp_vendor')) ? urlParams.get('atp_vendor') : '';

    const valueOfC = {
      'default': `1992`,
      18: `1992`,
      19: `1995`,
      20: `1997`,
      21: `1996`,
      23: `1998`,
    };
    const c = valueOfC[option_id] || valueOfC['default'];
  
    const redirectionLink = `https://siliconmarket.org/?a=${a}&c=${c}&p=${p}&s1=${s1}&s2=${s2}&s3=${s3}&s4=${s4}&s5=${s5}&s6=${s6}`;
    return { redirectionLink }
  }
  
  export default UnqualifiedRedirectionLinkEnglish;
  