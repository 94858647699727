const questinnare1   = {
    questionId:24,
    question : "Whom are you looking for coverage?",
    answers: [
            {
                label: "Myself",
                value: "56"
            },
            {
                label: "My Family",
                value: "57",
            }
    ]
  };
  const questinnare2   = {
    questionId:25,
    question : "Do you have Medicare, Medicaid or VA health coverage?",
    answers: [
            {
                label: "NO",
                value: "58"
            },
            {
              label: "YES",
              value: "59",
              // modalShow: "Good news! You qualify for some fantastic deals. Tap the button below to unlock them, and be sure to check out each offer! 🤩🤩🥳🥳🥳",
          },
    ]
  };
  const questinnare3   = {
    questionId:26,
    question : "Which of the following do you have?",
    answers: [
            {
                label: "Medicare",
                value: "60",
                modalShow: "Good news! You qualify for some fantastic deals. Tap the button below to unlock them, and be sure to check out each offer! 🤩🤩🥳🥳🥳",
            },
            {
                label: "Medicaid",
                value: "61",
                modalShow: "Good news! You qualify for some fantastic deals. Tap the button below to unlock them, and be sure to check out each offer! 🤩🤩🥳🥳🥳",

            },
            {
                label: "Medicare & Medicaid",
                value: "62"
            },
            {
                label: "VA",
                value: "63"
            }
    ]
  };

  const questinnare4   = {
    questionId:27,
    question : "Do you have a Green Card, are you a Citizen, are you a Resident, or do you have a work permit?",
    answers: [
            {
                label: "YES",
                value: "64",
               
            },
            {
                label: "NO",
                value: "65"
            }
    ]
  };
 
  const questinnare5   = {
    questionId:28,
    question : "Do you make $60,000 per year?",
    answers: [
            {
                label: "YES",
                value: "66",
               
            },
            {
                label: "NO",
                value: "67"
            }
    ]
  };

  export { 
    questinnare1,
    questinnare2,
    questinnare3,
    questinnare4,
    questinnare5
  };
  