export const PhoneNumberConstants = {
    "default": "+18775360756",
    "ACA_V1_FB_JAMES": "+18775360756",
    "ACA_US_ESS1_META_FB_ART": "+18553940298",
    "ACA_US_ESSM_META_FB_GEOFF": "+18556730704",
    "default_SPH": "+18335063463",
    "ACA_US_ESP_FB_Art":"+18884140368",
    "ACA_US_ESP_FB_Geoff":"+18884141283",
    "ACA_US_ESP_FB_ART":"+18884140368",
    "ACA_US_ESP_FB_GEOFF":"+18884141283",
    "DEV_TEST_ACA_ESP_ART":"+18884140368",
    "DEV_TEST_ACA_ESP_GEOFF":"+18884141283",
    "PRE_TEST_ACA_ESP_ART":"+18884140368",
    "PRE_TEST_ACA_ESP_GEOFF":"+18884141283",
    "ACA_ENG_FB_CB_VAN":"+18556151793",
    "ACA_ESP_FB_CB_VAN":"+18556151799",
    "ACA_ENG_FB_META_ANTONIO":"+18444551366",
    "ACA_ESP_GEO_META_ART":"+18884140368",
    "ACA_VERONICA_ESP_ART":"+18884140368",
    "ACA_Veronica_YT_ESP_Art":"+18884140368",
    "ACA_ENG_ELLASTIPS_YT_ART":"+18553940298",
    "default_local_NO":"+18554531966"
};

