
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import ELTP_V2_QuestionnaireRadioSlideEnglish from '../Includes/Layouts/Questionnaire/ELTP_V2/ELTP_V2_QuestionnaireRadioSlideEnglish'
import { questinnare1, questinnare2, questinnare3, questinnare4,questinnare5 } from "../../Constants/ELTP_V1Questions";
import Loader from '../Includes/Layouts/ELTP_V1/Loader';
import UserDetails from '../Includes/Layouts/ELTP_V1/UserDetails';
import { useForm } from 'react-hook-form';
import { useDataIngestion } from '../../Hooks/useDataIngestion';
import VisitorParamsContext from '../../Contexts/VisitorParams';
import { queryString } from '../../Utility/QueryString';
import CallButton from '../Includes/Layouts/ELTP_V1/CallButton';
import country from '../../Constants/CountryConstants';
import { useGetState } from '../../Hooks/useGetState';
import useJourneyStats from '../../Hooks/useJourneyStats';
import confetti from 'canvas-confetti';
import {DefaultZipCode} from '../../Constants/Constants';
import RingbaApi from "../../Utility/RingbaApi";
import { usePhoneValidation } from '../../Hooks/usePhoneValidation';


const FormELTP_V2 = (props) => {
    const splitForm = useRef(null);
    const [clickedSlide, setClickedSlide] = useState();
    const [loader, setLoader] = useState('d-none');
    const [time, setTime] = useState(false);
    const [showLoader, setShowLoader] = useState("d-none");
    const [showSlide4, setShowSlide4] = useState("d-none");
    const [showTime, setShowTime] = useState(false);
    const saveJournyStats = useJourneyStats();
    const query = new URLSearchParams(window.location.search);
    const uuid = query.get("uuid");
    const locUUID = localStorage.getItem("uuid");
    const campaign_name = "english";
    const { response } = RingbaApi(campaign_name);


    useEffect(() => {
        if (clickedSlide) {
            dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
            if (clickedSlide.slide == "question_28") {
                setLoader("d-block");
                setTimeout(() => {
                    setLoader("d-none");
                }, 2000);
            }
        }
    }, [clickedSlide]);

    
    useEffect(() => {
        if (locUUID)
            (async () => {
                await saveJournyStats(
                    uuid,
                    "question",
                    24,
                    "",
                    "view",
                    props.splitName
                );
            })();
    }, [locUUID]);

    useEffect(() => {
        if (clickedSlide) {
            dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
            if (clickedSlide.slide == "CallButton") {
                setLoader("d-block");
                setTimeout(() => {
                    setLoader("d-none");
                    window.scrollTo(0, 0);
                }, 2000);
            }
        }
    }, [clickedSlide]);
    useEffect(() => {
        (async () => {
            const handleSlideClick = async (slideType, questionNumber) => {
                await saveJournyStats(uuid, slideType, questionNumber, '', 'view', props.splitName);
            };

            if (clickedSlide) {
                if (clickedSlide.slide === 'question_24') {
                    await handleSlideClick('question', '25');
                } else if (clickedSlide.slide === 'question_25' && clickedSlide.optionVal == "58") {
                    await handleSlideClick('question', '28');
                } else if (clickedSlide.slide === 'question_25' && clickedSlide.optionVal == "59") {
                    await handleSlideClick('question', '26');
                } else if (clickedSlide.slide === 'question_26') {
                    await handleSlideClick('question', '28');
                } else if (clickedSlide.slide === 'question_28') {
                    await handleSlideClick('USER_BUTTON', 'user_details');
                }
                else if (clickedSlide.slide === 'CallButton') {
                    setTime(true);
                    await handleSlideClick('Button', 'CALL_CLICK');
                }
            }
        })()

    }, [clickedSlide]);
    const { register, errors, clearErrors, setError, trigger, getValues, handleSubmit } = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    });
    const initialState = {
        showQuestionSlide1: "d-block",
        frontText: "d-block",
        showQuestionSlide2: "d-none",
        showQuestionSlide3: "d-none",
        showQuestionSlide4: "d-none",
        userDetails: "d-none",
        callbutton: "d-none",
        countryState: "d-block",
    };
    const QuestionReducer = (state, action) => {
        switch (action.type) {
            case "nextQuestionSlide": {
                if (action.payload.clickedSlide.slide == "question_24") {
                    return {
                        ...state,
                        showQuestionSlide1: "d-none",
                        showQuestionSlide2: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_25" && action.payload.clickedSlide.optionVal == 59) {
                    return {
                        ...state,
                        showQuestionSlide2: "d-none",
                        showQuestionSlide3: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_26") {
                    return {
                        ...state,
                        showQuestionSlide3: "d-none",
                        showQuestionSlide4: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_25" && action.payload.clickedSlide.optionVal == 58) {
                    return {
                        ...state,
                        showQuestionSlide2: "d-none",
                        showQuestionSlide4: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_28") {
                    return {
                        ...state,
                        showQuestionSlide4: "d-none",
                        userDetails: "d-block",
                        countryState: "d-none",
                        frontText: "d-none"
                    };

                } else if (action.payload.clickedSlide.slide == "CallButton") {
                    return {
                        ...state,
                        userDetails: "d-none",
                        callbutton: "d-block",
                    }
                }
                else {
                    return {
                        ...state,
                    };
                }
            }
        }
    };
    const [state, dispatch] = useReducer(QuestionReducer, initialState);
    const [getState, setGetstate] = useState();
    const [zip,setZip] = useState();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const { saveDataIngestion } = useDataIngestion();
    const userQuestionnaire = useRef(null);
    const { getUserState } = useGetState();
    const { phoneValidation } = usePhoneValidation();

    let user_state = "";
    let country_code = "";
    let post_code = "";

    useEffect(
        () => {
            (async () => {
                const response = await getUserState(user_state);
                user_state = response.data.state_name;
                country_code = response.data.country_code;
                post_code = response.data.post_code;
                setZip(post_code);
           
            if (country_code == "" || country_code == "IN") {
                setGetstate("default");
                setZip(DefaultZipCode)
            }
            else {
                setGetstate(user_state);
            }
        })()
        }, []);
    let countryImg = "";
    let countryRate = "";
    if (getState) {
        const contrypic = country[0][getState];
        countryImg = contrypic.Image;
        countryRate = contrypic.Price;
    }
    if (getState == "default" || getState == "") {
        setGetstate("California");
    }
    const saveJournyfun = async (e, radioName) => {
        const form = splitForm.current;
        let radio_id = e.target.dataset.question_id;
        var option_id = e.target.value;
        props.setOptionValue(option_id);
        if (radioName == "question_26") {
            await saveJournyStats(
                uuid,
                "question",
                radio_id,
                option_id,
                "click",
                props.splitName
            );
        }
    }

    const slideChange = async (e, radioName) => {
        let question_id = radioName.split("_")?.[1];
        if (radioName == "question_28") {
            confetti({
                particleCount: 100,
                spread: 100,
                origin: { y: 0.2 },
            });
        }
       
        setClickedSlide({ slide: radioName, optionVal: e.target.value });
        await saveJournyStats(
            uuid,
            "question",
            question_id,
            e.target.value,
            "click",
            props.splitName
        );

        if (radioName == "question_28") {
            setShowLoader("d-block");
            setTimeout(() => {
                setShowLoader("d-none");
                setShowSlide4("d-block");
                setShowTime(true);
               
                    confetti({
                        particleCount: 100,
                        spread: 100,
                        origin: { y: 0.2 },
                    });
              
            }, 2000);

        }
    }
    const handleClick = async (e) => {
        const values = getValues();
        const formData = values;
        formData.lstSalutation = ''
        formData.txtLName = '';
        formData.txtEmail = '';
        formData.page_name = 'ELTP_V2';
        const getPhoneValidation = await phoneValidation(formData.txtPhone);
        const mobileCheck = getPhoneValidation.data.status_code;
        if(mobileCheck == 0){
            setError("txtPhone", {
                type: "manual",
                message: "Please enter the phone number.",
            });
        }
        if (mobileCheck == 1) {
            let formSubmit = await saveDataIngestion(
                visitorParameters.visitor_parameters,
                formData,
                "split_form_submit",
                props.splitName,
                queryString,
                visitorParameters.data,
            )
            await saveJournyStats(
                uuid,
                "USER_BUTTON",
                "user_details",
                "",
                "click",
                props.splitName
            );

            setClickedSlide({ slide: "CallButton" });
            confetti({
            particleCount: 100,
            spread: 100,
            origin: { y: 0.2 },
        });
        }
    }
    return (
        <>
            {/*============ BANNER-SECTION =============*/}
            <section className="banner_section" id="main_slide1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-12 main_sec  ">
                            <p className={`yellow_head ${state.frontText}`}>
                                <span>
                                    <b>
                                    2023 Federal subsidies for Obamacare: $110,000,000,000 ($110 billion)*

                                    </b>
                                </span>
                            </p>
                            <h2 className={`${state.frontText}`}>
                                Unlock up to{" "}
                                <span className="highlight_circle">
                                   $5800{" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 206.22677 92.09788"
                                    >
                                        <path d="m68.68338,90.55086c41.8618,5.55038,122.6888-3.45741,135.7148-33.14885,6.5944-15.03114-4.3888-33.42366-37.155-42.44813-36.8486-10.14874-83.387-6.40416-118.6473,5.30396-3.1379,1.04194-7.73518,2.32741-5.8036,5.56066,2.74141,3.76268,8.8096.24523,12.2383-.894,60.876-20.2273,142.1503-10.84913,147.747,21.05493,4.7388,27.01332-52.4573,38.66377-89.51088,40.82186-30.26962,1.76304-66.57622-2.30871-88.84311-17.49122-12.4443-8.48507-17.5326-20.41272-10.9208-32.29239,1.0322-1.8546,2.2107-4.0217,3.8153-5.61639C27.50848,21.27438,59.62728,6.87696,104.74977,5.23452c11.8007-.42961,23.6434-.03099,35.3721,1.0003,2.9684.26103,10.1223,2.23012,11.0284-1.20985.5642-3.6315-7.7591-3.26767-10.7683-3.56073-42.55428-4.14439-89.4265-.0739-122.4145,19.75389-3.2397,1.94734-7.0931,4.1835-9.4736,6.77454-21.4463,23.3433-2.601,54.23283,60.1895,62.55818Z"></path>
                                    </svg>
                                </span>{" "}
                                in tax subsidies for health insurance. Latinos are using the savings for food, bills, and to enjoy life!
                            </h2>
                            <p className={`sub_head01 ${state.frontText}`}>
                            Register right now to receive health insurance for <span>$0 per month</span>. Start now by answering some questions online to find the best provider.
                            </p>
                            <div className="form_slide">
                                <form ref={userQuestionnaire}>
                                    <ELTP_V2_QuestionnaireRadioSlideEnglish
                                        showQuestionSlide={state.showQuestionSlide1}
                                        questionnaire={questinnare1}
                                        slideChange={slideChange}
                                        validation={register}
                                        validationMsg={errors}
                                        trigger={trigger}
                                        getValues={getValues}
                                        setModalBody={props.setModalBody}
                                    />
                                    <ELTP_V2_QuestionnaireRadioSlideEnglish
                                        showQuestionSlide={state.showQuestionSlide2}
                                        questionnaire={questinnare2}
                                        // previousSlide={previousSlide}
                                        slideChange={slideChange}
                                        // backBtn={false}
                                        validation={register}
                                        validationMsg={errors}
                                        trigger={trigger}
                                        getValues={getValues}
                                        setModalBody={props.setModalBody}
                                    />
                                    <ELTP_V2_QuestionnaireRadioSlideEnglish
                                        showQuestionSlide={state.showQuestionSlide3}
                                        questionnaire={questinnare3}
                                        // previousSlide={previousSlide}
                                        slideChange={slideChange}
                                        // backBtn={false}
                                        validation={register}
                                        validationMsg={errors}
                                        trigger={trigger}
                                        getValues={getValues}
                                        setModalBody={props.setModalBody}
                                        saveJournyfun={saveJournyfun}
                                    />
                                    <ELTP_V2_QuestionnaireRadioSlideEnglish
                                        showQuestionSlide={state.showQuestionSlide4}
                                        questionnaire={questinnare5}
                                        // previousSlide={previousSlide}
                                        slideChange={slideChange}
                                        // backBtn={false}
                                        validation={register}
                                        validationMsg={errors}
                                        trigger={trigger}
                                        getValues={getValues}
                                        setModalBody={props.setModalBody}
                                    />
                                </form>
                            </div>
                        </div>
                        <div className={`col-lg-4 col-md-4 col-sm-12 col-12 d-sm-none d-md-block d-none `}>
                        </div>
                    </div>
                </div>
            </section>
            <form ref={userQuestionnaire}>
                <UserDetails
                    showQuestionSlide={state.userDetails}
                    loader={loader}
                    register={register}
                    validationMsg={errors}
                    trigger={trigger}
                    clearErrors={clearErrors}
                    setError={setError}
                    handleClick={handleClick}
                    handleSubmit={handleSubmit}
                    getValues={getValues}
                    contrypic={countryImg}
                    getState={getState}
                    country={country}
                    countryRate={countryRate}
                    post_code={zip}
                    showSlide4={showSlide4}
                />
               
                {/*======== Loader ==========*/}

                {/*============ BANNER-SECTION 2 =============*/}

                {/* THANKYOU SECTION */}
                <CallButton
                    showQuestionSlide={state.callbutton}
                    splitName={props.splitName}
                    timeLoad={time}
                    response={response}
                />
            </form>
         
        </>
    )
}

export default FormELTP_V2;