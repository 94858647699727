import React from "react";
import logo from "../../../../assets/img/SPH_CDV1/logo.png";
import Obamacare from "../../../../assets/img/SPH_CDV1/Obamacare-Logo.png";
import Rolling from "../../../../assets/img/SPH_CDV1/Rolling.gif";
const Loader = ({loader,setLoader}) =>{
    return(
        <div className={`step-loading ${loader} `} id="myDiv">
        <div className="content">
            <div className="logo">
                <img src={logo} alt="" />
            </div>
            <div className="resolver-logo">
                <img src={Obamacare} alt="" />
            </div>
            <h5 className="text-center">Analyzing...</h5>
            <div className="loader_rolling">
                <img src={Rolling} alt="" />
            </div>
        </div>
    </div>
    )
}
export default Loader;