import React from "react";
const ELTP_V2_QuestionnaireRadioSlideEnglish = (props) => {

  const question_id = props.questionnaire.questionId;
  const radioName = 'question_' + question_id;
  const answerBlock = props.questionnaire.answers.map((item, index) => {
    return (

      <>
          <li>
            <input 
              tabIndex={index}
              type="radio"
              className="btn-check"
              id={'opt' + item.value}
              data-direction="next"
              name={radioName}
              data-question_id={question_id}
              value={item.value} data-answertext={item.label}
              autoComplete="off"
              // onClick={(e) => props.slideChange(e, radioName)}
              onClick={(e) => {
                if (item.modalShow) {
                  props.setModalBody(item.modalShow);
                  props.saveJournyfun(e, radioName);
                } else {
                  props.slideChange(e, radioName);
                }
                if(props.handleClick){
                  props.handleClick();
                }
              }}
              ref={props.validation()}
            />
            <label className={"option-check next0" + question_id} htmlFor={"opt" + item.value}>{item.label}</label>
          </li>
      </>

    )
  })
  return (
    <>
      <div className={`slides_frm ${props.showQuestionSlide}`} id={'slide_1' + question_id} >
          <h4>{props.questionnaire.question}</h4>
          <ul className="options01">
          {answerBlock}
          </ul>
      </div>
    </>
  )
}
export default ELTP_V2_QuestionnaireRadioSlideEnglish;