import React, { useState } from 'react'
import Header from '../../Includes/Layouts/ELTP_V1/Header';
import Footer from '../../Includes/Layouts/ELTP_V1/Footer';
import FormELTP_V1 from '../../Forms/FormELTP_V1';
import AdtopiaLoadLP from '../../../Utility/AdtopiaLoadLP';
import SimpleModal from '../SimpleModal';
import { useDataIngestion } from '../../../Hooks/useDataIngestion';
import "../../../../src/assets/css/ELTP_V1/custom.scss";
import UnqualifiedRedirectionLinkEnglish from "../../../Utility/UnqualifiedRedirectionLinkEnglish";


const ELTP_V1 = () => {
   
    const {saveDataIngestion} = useDataIngestion();
    const [modalBody, setModalBody] = useState('');
    const [optionValue, setOptionValue] = useState('');
    const query = new URLSearchParams(window.location.search);
  
    const uuid  = query.get("uuid");
    const ModalViewers =  async(click_status) =>{
        const viewersSubmit = await saveDataIngestion(
               uuid,
               [click_status],
               "unqualify_modal",
               "ELTP_V1",      
        );
        window.location = UnqualifiedRedirectionLinkEnglish(optionValue).redirectionLink;
      }

    return (
        <>
            <div className="ELTP_V1">
            <AdtopiaLoadLP PageType="LP" splitName="ELTP_V1" />
            <Header />
            <FormELTP_V1 splitName="ELTP_V1" setOptionValue={setOptionValue} setModalBody={setModalBody} />
            <Footer />
                <SimpleModal
                    modalBody={modalBody}
                    setModalBody={setModalBody}
                    ModalViewers={ModalViewers}
                    ModalButton="Claim Your Benefits"
                />
            <script src="https://cdn.jsdelivr.net/npm/tsparticles-confetti@2.12.0/tsparticles.confetti.bundle.min.js"></script>

            </div>
        </>

    )
}

export default ELTP_V1